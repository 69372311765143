<template>
    <div v-if="loaded" class="price" :style="{ width: width ? width + '%' : '25%' }" :class="[{ 'no-data': !price }]">
        <span class="price-text">{{ title }}</span>
        <span class="price-total" v-if="price">{{ price }}</span>
        <span class="no-data" v-else>{{ $t('empty_pages.no_data') }}</span>
    </div>
    <div class="loader-analytics" v-else :style="{ width: width ? width + '%' : '100%' }">
        <div class="price-text">{{ title }}</div>
        <img src="img/loader_primary.svg" alt="" />
    </div>
</template>

<script>
export default {
    name: 'PriceModule',
    data() {
        return {}
    },

    props: {
        price: {
            type: undefined,
            required: true,
            nullable: true
        },

        title: {
            type: String,
            required: true
        },

        width: {
            type: Number,
            required: false
        },
        loaded: {
            type: Boolean,
            required: false
        }
    }
}
</script>

<style scoped lang="scss">
.price {
    background: $color-white;
    padding: 8px 16px;
    @include display-flex();
    @include border-radius();
    flex-direction: column;
    .price-text {
        font-family: $text;
    }

    .price-total {
        @include font-size(xl);
        color: $color-primary-500;
        font-family: $text-black;
    }

    .no-data {
        font-family: $text;
        @include font-size(ml);
        background-color: $color-neutral-200;
        padding: 8px;
    }
}
</style>
