<template>
    <div class="filter date" v-if="currentValue">
        <label>{{ $t('supervise.checklists.table_headers.date') }}</label>
        <v-select
            :value="currentValue.type_date"
            :class="{
                filtered: currentValue.type_date,
                changed: JSON.stringify(currentValue.type_date) != JSON.stringify(activeValue.type_date)
            }"
            @input="
                (value) => {
                    $emit('inputChange', {
                        inputName: 'type_date',
                        value: value
                    })
                }
            "
            :items="optionsDate"
            hide-details
            class="select"
            item-text="name"
            item-value="value"
            dense
            :no-data-text="$t('supervise.filter.no_results')"
        ></v-select>

        <!-- CUSTOM DATE -->
        <div class="custom" v-if="currentValue.type_date == 'custom'">
            <!-- START DATE -->
            <div class="description">{{ $t('assets.filter.from') }}:</div>

            <input
                :value="currentValue.start_date"
                @input="
                    (event) => {
                        $emit('inputChange', {
                            inputName: 'start_date',
                            value: event.target.value
                        })
                    }
                "
                :max="currentValue.end_date"
                type="date"
                class="date-input start"
            />

            <div class="separator"></div>

            <!-- END DATE -->
            <div class="description">{{ $t('assets.filter.to') }}:</div>

            <input
                :value="currentValue.end_date"
                @input="
                    (event) => {
                        $emit('inputChange', {
                            inputName: 'end_date',
                            value: event.target.value
                        })
                    }
                "
                :min="currentValue.start_date"
                type="date"
                class="date-input end"
            />
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'InputDate',
    props: {
        currentValue: { type: undefined },
        activeValue: { type: undefined }
    },
    data() {
        return {}
    },
    computed: {
        optionsDate() {
            const optionsDate = [
                {
                    value: 'today',
                    name: i18n.t('supervise.filter.today')
                },
                {
                    value: 'yesterday',
                    name: i18n.t('supervise.filter.yesterday')
                },
                {
                    value: 'week',
                    name: i18n.t('supervise.filter.week')
                },
                {
                    value: 'month',
                    name: i18n.t('supervise.filter.month')
                },
                {
                    value: 'quarter',
                    name: i18n.t('supervise.filter.quarter')
                },
                {
                    value: 'custom',
                    name: i18n.t('supervise.filter.custom')
                }
            ]

            return optionsDate
        }
    }
}
</script>

<style lang="scss" scoped></style>
