<template>
    <div id="content" class="beta-analytics">
        <div class="top-bar-actions">
            <DropdownMenuWithSearchBar :items="reportsToSelect" :selectedItem="selectedReport" @on-item-selected="handleDisplayReport" />
        </div>

        <div class="analytics-container" v-if="reportId">
            <AnalyticsSidebarFilters :reportId="reportId" @onApplyFilters="(params) => loadModules(params)" />

            <div class="data-table scrollbar analytics-data">
                <div class="scrollbar-part" :class="{ 'no-loaded': allModulesLoaded }">
                    <h2 class="title">{{ report.title }}</h2>

                    <template v-for="[id, reportSelected] in Object.entries(report.modules)">
                        <PriceModule
                            v-if="reportSelected.type === 'number' && modules[id]"
                            :price="modules[id][0].total"
                            :title="reportSelected.title"
                            :width="reportSelected.width"
                            :loaded="modulesLoaded[id]"
                        ></PriceModule>
                        <ChartModule
                            v-if="reportSelected.type === 'graph' && modules[id]"
                            :title="reportSelected.title"
                            :data="formatDataToChart(modules[id])"
                            :width="reportSelected.width"
                            :loaded="modulesLoaded[id]"
                        ></ChartModule>
                        <AnalyticsTable
                            v-if="reportSelected.type === 'table' && modules[id]"
                            :title="reportSelected.title"
                            :width="reportSelected.width"
                            :data="modules[id]"
                            :loaded="modulesLoaded[id]"
                            :ref="'table_' + id"
                            @loadTableModule="loadDataTable(id)"
                        ></AnalyticsTable>
                    </template>
                </div>
            </div>
        </div>
        <div v-else class="landing-page">
            <h1 class="landing-title">{{ $t('analytic.title') }}</h1>
            <span class="landing-description">{{ $t('analytic.description') }}</span>
            <span class="helpCenter" v-html="$t('analytic.helpCenter')"></span>
            <span class="landing-img"></span>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
import AnalyticsSidebarFilters from '../components/domain/analytics/AnalyticsSidebarFilters.vue'
import AnalyticsTable from '@/components/domain/analytics/AnalyticTable.vue'
import PriceModule from '../components/domain/analytics/PriceModule.vue'
import ChartModule from '../components/domain/analytics/ChartModule.vue'
import BarsModule from '../components/domain/analytics/BarsModule.vue'
import DropdownMenuWithSearchBar from '../components/layout/DropdownMenuWithSearchBar.vue'

export default {
    name: 'BetaAnalytics',
    components: {
        apexchart: ApexCharts,
        AnalyticsSidebarFilters,
        AnalyticsTable,
        PriceModule,
        ChartModule,
        BarsModule,
        DropdownMenuWithSearchBar
    },
    data() {
        return {
            typeOfChart: 'bars',
            reportId: null,
            modulesLoaded: {},
            allModulesLoaded: false,
            currentFilters: false,
            selectedReport: null
        }
    },
    computed: {
        report() {
            const report = this.$store.getters['analytics/getCurrentReport']
            const reportValues = report.data
            return reportValues
        },
        modules() {
            const allModules = this.$store.getters['analytics/getReportData'](this.reportId)
            return allModules
        },
        reportsToSelect() {
            const reports = this.$store.getters['analytics/getReports']

            if (reports) {
                const newReports = Object.values(reports)

                const reportsToSelect = newReports.map((report) => {
                    return {
                        id: report.id,
                        name: report.data.title
                    }
                })

                return reportsToSelect
            }
        }
    },
    methods: {
        changeType(type) {
            this.typeOfChart = type
        },
        formatDataToChart(data) {
            const formattedData = Object.entries(data)
                .map(([key, value]) => {
                    return {
                        title: key,
                        value: value
                    }
                })
                .sort(function (a, b) {
                    return a.value < b.value ? 1 : -1
                })
            return formattedData
        },

        loadModules(params) {
            const modules = Object.keys(this.report.modules)
            this.modulesLoaded = { ...this.modulesLoaded, ...modules.reduce((acc, module) => ({ ...acc, [module]: false }), {}) }

            const defaultFilters = {
                start_date: 'week',
                pagination: {
                    page: 1,
                    items: 20
                }
            }

            modules.forEach((module) => {
                let filters = params ? params.filters : defaultFilters
                if (this.report.modules[module].type == 'table' && this.$refs['table_' + module]) {
                    filters.pagination = {
                        page: this.$refs['table_' + module][0].pagination.page,
                        items: this.$refs['table_' + module][0].pagination.itemsPerPage
                    }
                }
                this.$store
                    .dispatch('analytics/loadData', {
                        report_id: this.reportId,
                        module_id: module,
                        filters: JSON.stringify(filters)
                    })
                    .then(() => {
                        this.modulesLoaded = { ...this.modulesLoaded, [module]: true }
                        this.currentFilters = filters
                    })
            })

            this.allModulesLoaded = true
        },

        load() {
            this.$store.dispatch('analytics/loadReports').then(() => {
                const reports = this.$store.getters['analytics/getReports']
                const reportsArr = Object.values(reports)

                if (reportsArr.length === 0) {
                    return
                }

                const report = reportsArr[0]
                this.reportId = report.id

                this.$store.commit('analytics/setCurrentReport', this.reportId)
                this.setInitialSelectedReport()
                this.loadModules()
            })
        },

        loadDataTable(id) {
            this.modulesLoaded = { ...this.modulesLoaded, [id]: false }

            let filters = this.currentFilters
            filters.pagination = {
                page: this.$refs['table_' + id][0].pagination.page,
                items: this.$refs['table_' + id][0].pagination.itemsPerPage
            }

            this.$store
                .dispatch('analytics/loadData', {
                    report_id: this.reportId,
                    module_id: id,
                    filters: JSON.stringify(filters)
                })
                .then(() => {
                    this.modulesLoaded = { ...this.modulesLoaded, [id]: true }
                    this.currentFilters = filters
                })
        },

        handleDisplayReport(reportSelected) {
            this.selectedReport = structuredClone(reportSelected)
            this.reportId = this.selectedReport.id
            this.$store.commit('analytics/setCurrentReport', this.selectedReport.id)
            this.loadModules()
        },

        setInitialSelectedReport() {
            const currentReport = this.$store.getters['analytics/getCurrentReport']

            if (currentReport) {
                this.selectedReport = {
                    id: currentReport.id,
                    name: currentReport.data.title
                }
            }
        }
    },

    watch: {},

    created() {
        this.load()
    },

    mounted() {}
}
</script>

<style scoped lang="scss">
#content.beta-analytics {
    position: fixed;
    top: 60px;
    width: calc(100% - 50px);
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);

    .top-bar-actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 16px 24px;
        height: max-content;
    }
    .landing-page {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 546px;
        height: 539px;
        margin-left: 29%;
        margin-top: 112px;
        .landing-title {
            @include font-size(lg);
            font-family: $text-bold;
            margin-bottom: 1rem;
        }
        .landing-description {
            @include font-size(ml);
            font-family: $text;
            text-align: center;
            margin-bottom: 1rem;
        }

        .helpCenter {
            @include font-size(lg);
            font-family: $text;
            color: $color-secondary-500;
            margin-bottom: 1rem;
        }

        .landing-img {
            width: 546px;
            height: 329px;
            @include background($image: img('analytic_landing.svg'));
        }
    }

    .analytics-container {
        display: flex;
        align-items: stretch;
        gap: 0.5em;
        align-items: stretch;
        gap: 16px;
        padding: 0 24px;
        height: calc(100% - 91px);

        .analytics-data {
            padding: 8px;
            @include display-flex();
            flex-direction: column;
            //background: $color-primary-100;
            .scrollbar-part {
                height: calc(100% - 13px);
                overflow-y: auto !important;
                padding-right: 8px;
                @include display-flex();
                flex-wrap: wrap;

                &.no-loaded {
                    height: unset;
                }
                .title {
                    font-family: $text-light;
                    @include font-size(lg);
                    margin-bottom: 16px;
                    width: 100%;
                }

                .graphics {
                    display: flex;
                    //flex-direction: column;
                    gap: 0.5em;
                    gap: 16px;

                    .render-graphics {
                        display: flex;
                        gap: 0.5em;
                        align-items: stretch;
                        gap: 16px;
                    }
                }
            }
        }
    }
}
</style>
