<template>
    <div class="filter" v-if="currentValue">
        <label>{{ title }}</label>
        <!-- SITES (UBICACIONES) -->
        <v-select
            :value="currentValue.site"
            :class="{
                filtered: currentValue.site && activeValue.site && currentValue.site === activeValue.site,
                changed: currentValue.site !== activeValue.site
            }"
            hide-details
            class="select"
            @input="
                (value) => {
                    $emit('inputChange', { inputName: 'site', value: value })
                }
            "
            v-if="Object.values(optionsSites)"
            :items="Object.values(optionsSites)"
            item-text="name"
            item-value="value"
            dense
            :no-data-text="$t('supervise.filter.no_results')"
            :placeholder="$t('assets.filter.filter_by')"
        ></v-select>

        <!-- LOCATIONS -->
        <div class="sites" v-if="currentValue.site == 'locations'">
            <label class="description">{{ $t('user.filters.available_sites') }}</label>

            <v-autocomplete
                :value="currentValue.locations"
                :class="{
                    filtered: currentValue.locations && typeof currentValue.locations !== 'undefined' && Object.values(currentValue.locations).length > 0,
                    changed: JSON.stringify(currentValue.locations) != JSON.stringify(activeValue.locations)
                }"
                hide-details
                class="select"
                @input="
                    (locationsInputValue) => {
                        $emit('inputChange', {
                            inputName: 'locations',
                            value: locationsInputValue
                        })
                    }
                "
                v-if="Object.values(optionsLocations)"
                :items="Object.values(optionsLocations)"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="currentValue.locations ? '' : $t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>

            <Tags
                v-if="currentValue.locations"
                :tags="currentValue.locations"
                :backImage="'location_inactive_s30.svg'"
                :nameTag="'name'"
                value="id"
                :removable="true"
            ></Tags>
        </div>

        <!-- GROUPS -->
        <div class="custom" v-else-if="currentValue.site == 'groups'">
            <label class="description">{{ $t('user.filters.available_territories') }}</label>
            <v-autocomplete
                :value="currentValue.groups"
                :class="{
                    filtered:
                        currentValue.groups &&
                        typeof currentValue.groups !== 'undefined' &&
                        currentValue.groups &&
                        Object.values(currentValue.groups).length > 0,
                    changed: JSON.stringify(currentValue.groups) != JSON.stringify(activeValue.groups)
                }"
                hide-details
                class="select"
                @input="
                    (inputValue) => {
                        $emit('inputChange', {
                            inputName: 'groups',
                            value: inputValue
                        })
                    }
                "
                v-if="Object.values(optionsGroups)"
                :items="Object.values(optionsGroups)"
                item-text="name"
                item-value="id"
                multiple
                attach
                dense
                :no-data-text="$t('supervise.filter.no_results')"
                :placeholder="currentValue.groups ? '' : $t('assets.filter.filter_by')"
                return-object
            >
                <template v-slot:selection="{ item, index }">
                    {{ index == 0 ? '' : '' }}
                </template>
            </v-autocomplete>

            <Tags v-if="currentValue.groups" :tags="currentValue.groups" :nameTag="'name'" value="id" :removable="true"></Tags>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    name: 'InputsSelectLocation',
    components: { Tags },
    props: {
        title: { type: String },
        currentValue: { type: undefined },
        activeValue: { type: undefined },
        optionsLocations: { type: Array },
        optionsGroups: { type: Array }
    },
    data() {
        return {}
    },
    computed: {
        optionsSites() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                { value: 'groups', name: i18n.t('user.filters.territories') }
            ]
        }
    },
    watch: {},

    created() {}
}
</script>

<style lang="scss" scoped></style>
