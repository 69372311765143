<template>
    <div class="filter" v-if="items">
        <label>{{ title }}</label>

        <v-autocomplete
            :class="{
                filtered: currentValues && Object.values(currentValues).length > 0,
                changed: JSON.stringify(currentValues) != JSON.stringify(activeValues)
            }"
            :items="items"
            :value="currentValues"
            @input="
                (inputValue) => {
                    $emit('inputChange', {
                        inputName: filter,
                        value: inputValue
                    })
                }
            "
            :placeholder="$t('assets.filter.filter_by')"
            :no-data-text="$t('supervise.filter.no_results')"
            item-text="name"
            item-value="id"
            hide-details
            class="select"
            multiple
            attach
            dense
            return-object
        >
            <template v-slot:selection="{ item, index }">
                {{ index == 0 ? '' : '' }}
            </template>

            <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-row no-gutters class="options" v-html="item.name"> </v-row>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-autocomplete>

        <Tags :tags="currentValues" :nameTag="'name'" value="id" :removable="true"></Tags>
    </div>
</template>

<script>
import Tags from '@/components/ui/Tags'

export default {
    name: 'InputSelect',
    components: { Tags },
    props: {
        title: { type: String },
        filter: { type: String },
        items: { type: Array },
        currentValues: { type: undefined },
        activeValues: { type: undefined }
    },
    data() {
        return {}
    },
    watch: {
        // currentValues(currentValues) {
        //     console.log('InputSelect currentValues:', this.currentValues)
        // },
        // activeValues(activeValues) {
        //     console.log('InputSelect activeValues:', this.activeValues)
        // }
    }
}
</script>

<style lang="scss" scoped></style>
