<template>
    <div id="report-table" class="table-module" :style="{ width: width ? width + '%' : '100%' }">
        <div class="title">{{ title }}</div>

        <v-data-table
            v-if="loaded"
            class="report-table-module"
            :headers="headers"
            fixed-header
            :items="items"
            hide-default-footer
            :page.sync="pagination.page"
            :items-per-page="pagination.itemsPerPage"
            @page-count="pageCount = $event"
            :options.sync="pagination"
            @click:row="handleClick"
        >
            <template v-slot:no-data>
                <div class="no-data-container">
                    <div class="no-data">
                        {{ $t('empty_pages.no_result') }}
                    </div>
                </div>
            </template>
            <template v-slot:footer="{ itemsPerPage, page, itemsLength, totalPages }">
                <div class="table-footer">
                    <div class="totalItems">
                        <span class="total">
                            <strong class="total-results">{{ pagination.totalItems }}</strong>
                            {{ $t('supervise.checklists.table_headers.results') }}
                        </span>
                    </div>
                    <v-pagination v-model="pagination.page" @input="changePage" color="#2c52c4" :length="pages" :total-visible="maxPageVisibles"></v-pagination>
                    <div class="rows-page">
                        <v-select @change="changePage" :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                    </div>
                </div>
            </template>
        </v-data-table>
        <div class="loader-analytics" v-else :style="{ width: width ? width + '%' : '100%' }">
            <img src="img/loader_primary.svg" alt="" />
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'AnalyticTable',
    props: {
        data: {
            type: Object,
            required: true,
            nullable: true
        },
        /*summary: {
            type: Boolean,
            required: true,
            default: false
        },*/
        width: {
            type: Number,
            required: false
        },
        title: {
            type: String,
            required: true
        },
        loaded: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            maxPageVisibles: 10
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.itemsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
        },
        headers() {
            let headers = []
            for (var idx in this.data.headers) {
                var column = this.data.headers[idx]
                headers.push({
                    text: i18n.t(column.title),
                    value: column.id,
                    align: 'start',
                    sortable: false,
                    class: 'header-table'
                })
            }
            return headers
        },
        items() {
            let rows = []
            for (var idx in this.data.rows) {
                var row = this.data.rows[idx]
                rows.push(row)
            }
            return rows
        }
    },
    methods: {
        changePage() {
            this.$emit('loadTableModule')
        },
        handleClick(item) {
            if(item.url){
                window.open(item.url, '_blank')
            }
        }
    },
    watch: {
        'data.total': {
            handler: function (newVal, oldVal) {
                this.pagination.totalItems = newVal
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.table-module {
    padding: 24px 0;
    .title {
        font-family: $text-light !important;
        @include font-size(ml, $important: true);
        margin-bottom: 8px;
    }
}
</style>

<style lang="scss">
.loader-analytics {
    background: $color-white;
    @include border-radius();
    padding: 8px 16px;
}
#report-table {
    .report-table-module {
        height: 100% !important;
    }

    .v-select__selections {
        opacity: 0;
    }
    .v-text-field .v-input__control {
        height: 40px;
    }
    .v-text-field .v-label {
        font-family: $text;
        @include font-size(ml);
        top: 22px;
    }
    .v-pagination__item--active {
        background-color: $color-neutral-200 !important;
        color: $color-black;
    }
    .no-data-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .no-data {
        font-family: $text;
        @include font-size(ml);
        background-color: $color-neutral-200;
        width: 300px;
        padding: 8px;
    }
    .table-footer {
        background-color: $color-white;
        border-top: 1px solid #e2e2e2;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0 4px 0 16px;
        height: 50px;
        position: relative;
        z-index: 5;

        .totalItems {
            display: flex;
            align-items: center;
            .total,
            .total-results {
                color: $color-neutral-600;
            }
        }
    }
    .report-table-module.v-data-table table th {
        height: auto !important;
        padding: 8px !important;
        background-color: $color-primary-500 !important;
        span {
            color: $color-white !important;
            @include font-size(xs, $important: true);
        }
    }
    .report-table-module.v-data-table table tr td {
        height: auto !important;
        padding: 8px !important;
    }
}
</style>
