<template>
    <div class="sidebar-filters">
        <div class="filter-box">
            <div class="title">
                {{ $t('assets.filter.filter_data') }}
            </div>

            <div class="filter-items" v-if="filters">
                <template v-for="filter in filters">
                    <!-- TEXT -->
                    <template v-if="filter.type === 4">
                        <FilterInput
                            type="text"
                            :title="filter.title"
                            :filter="filter.item"
                            :currentValue="currentFilters[filter.item]"
                            :activeValue="activeFilters[filter.item]"
                            @inputChange="(params) => updateCurrentFilters(params)"
                        />
                    </template>

                    <!-- MULTIPLE -->
                    <template v-if="filter.type === 11">
                        <FilterInput
                            type="select"
                            :title="filter.title"
                            :filter="filter.item"
                            :inputSelectItems="Object.values(filter.values)"
                            :currentValue="currentFilters[filter.item]"
                            :activeValue="activeFilters[filter.item]"
                            @inputChange="(params) => updateCurrentFilters(params)"
                        />
                    </template>

                    <!-- STATUS -->
                    <template v-if="filter.type === 'status'">
                        <FilterInput
                            type="select"
                            :title="$t('analytics.filters.status')"
                            filter="status"
                            :inputSelectItems="Object.values(filter.values)"
                            :currentValue="currentFilters[filter.item]"
                            :activeValue="activeFilters[filter.item]"
                            @inputChange="(params) => updateCurrentFilters(params)"
                        />
                    </template>

                    <!-- SITE (LOCATIONS OR GROUPS) -->
                    <template v-if="filter.type === 'site'">
                        <FilterInput
                            type="site"
                            :title="filter.title.includes('.') ? $t(filter.title) : filter.title"
                            :currentValue="currentFilters ? currentFilters.site : ''"
                            :activeValue="activeFilters ? activeFilters.site : ''"
                            :optionsLocations="Object.values(filter.values.locations)"
                            :optionsGroups="Object.values(filter.values.groups)"
                            @inputChange="(params) => updateCurrentFilters(params)"
                        />
                    </template>

                    <!-- DATE -->
                    <template v-if="filter.type === 'date'">
                        <FilterInput
                            type="date"
                            :title="filter.title.includes('.') ? $t(filter.title) : filter.title"
                            :currentValue="currentFilters ? currentFilters.date : 'week'"
                            :activeValue="activeFilters ? activeFilters.date : 'week'"
                            @inputChange="(params) => updateCurrentFilters(params)"
                        />
                    </template>
                </template>
            </div>

            <FilterActions
                :showClearFiltersButton="showClearFiltersButton"
                :clearFiltersCallback="resetFilters"
                :filterCallback="applyFilters"
                :changesDetected="changesDetected"
            />
        </div>
    </div>
</template>

<script>
import FilterActions from '../../ui/FilterActions.vue'
import FilterInput from './FilterInput.vue'
import { getListOfIds } from '../../../../public/js/utils'

export default {
    name: 'AnalyticsSidebarFilters',
    components: { FilterActions, FilterInput },
    props: {
        reportId: { type: String }
    },
    data() {
        return {
            filters: null,
            currentFilters: null,
            emptyFilters: null,
            activeFilters: null,
            changesDetected: false
        }
    },
    computed: {
        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.emptyFilters)
        }
    },
    methods: {
        updateCurrentFilters(params) {
            const { inputName, value } = params

            const clonedFilters = structuredClone(this.currentFilters)

            if (inputName === 'type_date') {
                clonedFilters.date.type_date = value

                if (value !== 'custom') {
                    clonedFilters.date.start_date = ''
                    clonedFilters.date.end_date = ''
                }
            } else if (inputName === 'start_date') {
                clonedFilters.date.start_date = value
            } else if (inputName === 'end_date') {
                clonedFilters.date.end_date = value
            } else if (inputName === 'site') {
                clonedFilters.site.site = value

                clonedFilters.site.locations = []
                clonedFilters.site.groups = []
            } else if (inputName === 'locations') {
                clonedFilters.site.locations = value
            } else if (inputName === 'groups') {
                clonedFilters.site.groups = value
            } else if (value && Array.isArray(value)) {
                clonedFilters[inputName] = [...value]
            } else {
                clonedFilters[inputName] = value
            }

            this.currentFilters = clonedFilters
        },

        resetFilters() {
            this.$overlay.loading()

            this.currentFilters = structuredClone(this.emptyFilters)
            this.activeFilters = structuredClone(this.emptyFilters)
            this.applyFilters()

            this.changesDetected = false

            this.$overlay.hide()
        },

        transformFiltersForDB(currentFilters) {
            const newFilters = structuredClone(currentFilters)

            for (let key in newFilters) {
                if (newFilters.hasOwnProperty(key)) {
                    const filterValue = structuredClone(newFilters[key])

                    // filter that its value is an array of objects
                    if (Array.isArray(filterValue)) {
                        const arr = filterValue

                        if (arr.length > 0) {
                            const isAnArrayOfObjects = arr.every((el) => typeof el === 'object')

                            if (isAnArrayOfObjects) {
                                const transformedArr = getListOfIds(arr) // (returns an array of ids)

                                newFilters[key] = transformedArr
                            }
                        } else delete newFilters[key]

                        // LOCATIONS OR GROUPS
                    } else if (key === 'site') {
                        const site = filterValue.site

                        if (typeof site === 'string' && site.length > 0) {
                            const locations = filterValue.locations
                            const groups = filterValue.groups

                            if (locations && Array.isArray(locations) && locations.length > 0) {
                                const transformedLocations = getListOfIds(locations)

                                newFilters.locations = transformedLocations
                            }

                            if (groups && Array.isArray(groups) && groups.length > 0) {
                                const transformedGroups = getListOfIds(groups)

                                newFilters.groups = transformedGroups
                            }
                        }

                        delete newFilters.site

                        // DATE (START_DATE AND END_DATE)
                    } else if (key === 'date') {
                        const type_date = newFilters[key].type_date

                        if (type_date !== 'custom') {
                            newFilters.start_date = type_date
                            newFilters.end_date = null
                        } else {
                            const date = newFilters[key]

                            newFilters.start_date = date.start_date ? date.start_date : null
                            newFilters.end_date = date.end_date ? date.end_date : null
                        }

                        delete newFilters.date
                    } else if (typeof newFilters[key] === 'string') {
                        const value = newFilters[key]

                        if (value.length > 0) {
                        } else delete newFilters[key]
                    }
                }
            }

            return newFilters
        },

        applyFilters() {
            const currentFilters = structuredClone(this.currentFilters)

            const transformedFilters = this.transformFiltersForDB(currentFilters)

            this.$emit('onApplyFilters', { filters: transformedFilters })

            this.activeFilters = currentFilters

            this.changesDetected = false
        },

        async loadFilters() {
            if (!this.reportId) {
                return
            }

            const filtersAreLoaded = await this.$store.dispatch('analytics/loadFilters', this.reportId)

            if (filtersAreLoaded) {
                const optionsFilters = Object.values(this.$store.getters['analytics/getFilters'])

                this.filters = optionsFilters

                this.setFilterInputsInitialValues()
            }
        },

        setFilterInputsInitialValues() {
            if (!this.filters) {
                return
            }

            const inputs = {}

            this.filters.forEach((filter) => {
                if (filter.type === 'date') {
                    inputs.date = {
                        type_date: 'week',
                        start_date: '',
                        end_date: ''
                    }
                }

                // 4 = text
                if (filter.type === 4) {
                    inputs[filter.item] = ''
                }

                // 11 = multiple
                if (filter.type === 11) {
                    inputs[filter.item] = []
                }

                if (filter.type === 'status') {
                    inputs[filter.item] = []
                }

                if (filter.type === 'site') {
                    inputs.site = {
                        site: '',
                        groups: [],
                        locations: []
                    }
                }
            })

            this.emptyFilters = structuredClone(inputs)
            this.currentFilters = structuredClone(this.emptyFilters)
            this.activeFilters = structuredClone(this.emptyFilters)
        }
    },

    watch: {
        reportId(newReportId) {
            this.loadFilters()
        },

        currentFilters: {
            handler: function (val, oldVal) {
                if (JSON.stringify(val) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
            },
            deep: true
        }
    },

    created() {
        this.loadFilters()
    }
}
</script>

<style lang="scss" scoped></style>
