<template>
    <div v-if="items && selectedItem" class="dropdown" ref="dropdownRef">
        <div class="dropdown-button-container">
            <button class="dropdown-button" @click="toggleDropdown">{{ selectedItem.name }}</button>
        </div>
        <div class="dropdown-list-container" v-if="isOpen">
            <input type="search" v-model="searchValue" :placeholder="$t('assets.views.search_view')" class="search-input" />

            <div v-if="searchValue.length > 0 && noResultsAfterSearch">
                <p class="noSearchResultsMsg">{{ noResultsAfterSearchMsg }}</p>
            </div>

            <ul v-else-if="filteredItems.length > 0" class="dropdown-list" tabindex="0">
                <li v-for="item in filteredItems" @click="handleItemSelected(item)">{{ itemName(item) }}</li>
            </ul>

            <ul v-else class="dropdown-list" tabindex="0">
                <li v-for="item in items" @click="handleItemSelected(item)">{{ itemName(item) }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'DropDownMenuWithSearchBar',
    props: {
        items: { type: Array },
        selectedItem: { type: Object },
        customNoSearchResultMsg: { type: String }
    },
    data() {
        return {
            isOpen: false,
            searchIsVisible: false,
            // search:
            searchValue: '',
            filteredItems: [],
            debounceTimer: null,
            noResultsAfterSearch: false
        }
    },

    computed: {
        selectedItemName() {
            if (this.selectedItem) {
                return this.selectedItem.name
            } else return ''
        },

        noResultsAfterSearchMsg() {
            const defaultMessage = i18n.t('supervise.filter.no_results')

            return this.customNoSearchResultMsg ? this.customNoSearchResultMsg : defaultMessage
        }
    },

    watch: {
        searchValue(newSearchValue, oldSearchValue) {
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer)
            }

            if (newSearchValue !== '') {
                this.debounceTimer = setTimeout(() => {
                    this.filteredItems = this.filterItems(newSearchValue)
                }, 300)
            } else this.filteredItems = []
        }
    },

    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen
        },

        handleItemSelected(clickedItem) {
            this.$emit('on-item-selected', clickedItem)

            this.closeDropdown()
        },

        closeDropdown() {
            this.isOpen = false
            this.searchValue = ''
            this.filteredItems = []
        },

        filterItems(value) {
            function normalize(string) {
                return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            }

            const words = normalize(value.toLowerCase()).split(' ')

            const filteredItems = this.items.filter((view) => {
                const viewName = normalize(view.name.toLowerCase())

                return words.every((word) => viewName.includes(word))
            })

            if (filteredItems.length === 0) {
                setTimeout(() => {
                    this.noResultsAfterSearch = true
                }, 600)
            } else this.noResultsAfterSearch = false

            return filteredItems
        },

        checkClickOutside(e) {
            const dropdown = this.$refs.dropdownRef
            const target = e.target

            if (dropdown !== target && !dropdown.contains(target)) {
                this.closeDropdown()
            }
        },

        itemName(item) {
            if (item.hasOwnProperty('name')) {
                return item.name
            }
        }
    },

    created() {
        document.addEventListener('click', this.checkClickOutside)
    },

    destroyed() {
        document.removeEventListener('click', this.checkClickOutside)
    }
}
</script>

<style lang="scss" scoped>
.dropdown {
    position: relative;
    display: inline-block;
    width: 291px;
}

.dropdown-button-container {
    position: relative;
    width: 100%;
}

.dropdown-button {
    position: relative;
    @include font-size('sm');
    color: $color-black;
    background-image: img('down_caret_neutro_s90.svg');
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: 94%;
    background-color: $color-white;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 35px;
    padding: 0px 10px;
    margin: 0 !important;
    min-width: 286px;
    max-width: 286px;
    text-align: start;
    font-family: $text;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 1.2em;
    border: 1px solid $color-neutral-300;
}

.search-input {
    @include font-size('sm');
    font-family: $text;
    color: $color-black;
    border: none;
    width: 180px;
    padding: 0.6em 1em 0;
}

.dropdown-list-container {
    position: absolute;
    top: 100%;
    left: 0;
    max-height: 600px;
    overflow-y: scroll;
    background-color: $color-white;
    list-style: none;
    margin-top: 3px;
    width: 100%;
    z-index: 20;
    border-radius: 5px;
    border: 1px solid $color-neutral-200;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.dropdown-list {
    @include font-size('sm');
    display: flex;
    flex-direction: column;
    padding: 0;
}

.no-subtitles {
    padding-top: 0.5em;
}

.subtitle {
    @include font-size('sm');
    font-family: $text-medium;
    padding-left: 1em;
    padding-top: 1em;
}
.dropdown-list li {
    color: $color-black;
    padding: 0.5em 1em 0.5em 2em;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: $text;
    color: $color-neutral-600;
}

.dropdown-list li:hover {
    background-color: $color-neutral-200;
}

.create-view-btn {
    font-family: $text-medium;
    @include font-size('sm');
    width: 100%;
    text-align: left;
    background-image: img('add_black.svg');
    background-position: 11px center;
    background-size: inherit;
    color: $color-black;
    background-color: $color-white;
    margin: 0px !important;
    padding: 1.5em 1em 1.5em 2em;
    display: flex;
    align-items: center;

    &:hover {
        background-color: $color-neutral-300;
    }
}

.noSearchResultsMsg {
    padding: 1.5em 1em 1em 1em;
    font-family: $text;
    color: $color-neutral-600;
    text-align: center;
}
</style>
