<template>
    <div id="report-chart-bar" class="chart-module" :style="{ width: width ? width + '%' : '50%' }">
        <header>
            <div class="chart-module-title">
                {{ title }}
            </div>
            <div class="toggle-graphic">
                <div class="toggle-box">
                    <div class="type-of-graph" :class="{ selected: typeOfChart === 'bars' }" @click="changeType('bars')">
                        <img src="img/chart_bar.svg" alt="" />
                    </div>
                    <div class="type-of-graph" :class="{ selected: typeOfChart === 'pie' }" @click="changeType('pie')">
                        <img src="img/chart_donut.svg" alt="" />
                    </div>
                </div>
            </div>
        </header>
        <div class="chart-module-content" v-if="loaded">
            <template v-if="typeOfChart === 'bars' && data && data.length > 0">
                <BarsModule :data="data"></BarsModule>
            </template>
            <template v-else-if="typeOfChart === 'pie' && data && data.length > 0">
                <PieModule :data="data"></PieModule>
            </template>
            <template v-else>
                <span class="no-data">{{ $t('empty_pages.no_data') }}</span>
            </template>
        </div>
        <div class="loader-analytics" v-else :style="{ width: width ? width + '%' : '100%' }">
            <img src="img/loader_primary.svg" alt="" />
        </div>
    </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
import BarsModule from './BarsModule.vue'
import PieModule from './PieModule.vue'

export default {
    name: 'ChartModule',
    components: {
        apexchart: ApexCharts,
        BarsModule,
        PieModule
    },
    props: {
        title: {
            type: String,
            required: true
        },
        data: {
            type: Array,
            required: true,
            nullable: true
        },
        width: {
            type: Number,
            required: false
        },
        loaded: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            typeOfChart: 'bars'
        }
    },
    methods: {
        changeType(type) {
            this.typeOfChart = type
        }
    },

    created() {}
}
</script>

<style scoped lang="scss">
#report-chart-bar {
    background: $color-white;
    padding: 8px;
    @include display-flex();
    @include border-radius();
    flex-direction: column;

    header {
        display: flex;
        justify-content: space-between;
        .chart-module-title {
            font-family: $text-light !important;
            @include font-size(ml);
        }
    }

    .chart-module-content {
        @include font-size(lg);
        color: $color-primary-500;
        margin-top: 10px;
        .no-data {
            font-family: $text;
            @include font-size(ml);
            background-color: $color-neutral-200;
            width: 336px;
            padding: 8px;
            margin-top: 10px;
        }
    }

    .toggle-graphic {
        @include display-flex();
        @include justify-content(flex-end);
        .toggle-box {
            @include display-flex();
            gap: 8px;
            padding: 8px;
            @include border-radius();
            background: $color-neutral-200;
            cursor: pointer;
            .type-of-graph {
                position: relative;
                width: 20px;
                height: 20px;
                transition: all 0.2s ease-in-out;

                &.selected {
                    transform: scale(1.1);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
</style>
<style lang="scss">
#report-chart-bar {
    * {
        font-family: $text !important;
    }
    .apexcharts-text tspan {
        @include font-size(xs);
    }
}
</style>
