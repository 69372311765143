<template>
    <div id="report-chart-bar" class="bars-module">
        <div class="bars-module-content">
            <apexchart
                width="100%"
                height="400px"
                type="bar"
                :options="barOptions.chartOptions"
                :series="barOptions.series"
            ></apexchart>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
export default {
    name: 'BarsModule',
    components: {
        apexchart: ApexCharts
    },
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            barOptions: {
                series: [
                    {
                        name: this.title,
                        data: []
                    }
                ],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        font: 'SourceSans3-Regular'
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            horizontal: true
                        }
                    },
                    xaxis: {
                        axisTicks: {
                            show: true
                        },
                        tickAmount: 'dataPoints'
                    },
                    yaxis: {
                        tickAmount: 0,
                        labels: {
                            show: true,
                            align: 'left',
                            minWidth: 0,
                            maxWidth: 160
                        },
                        axisTicks: {
                            show: false
                        }
                    },
                    colors: chartColors,
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'middle',
                        offsetX: 0,
                        style: {
                            fontSize: '10px'
                        }
                    }
                }
            }
        }
    },

    created() {
        this.barOptions.series[0].data = this.data.map((item) => {
            return { x: item.title, y: item.value }
        })
    }
}
</script>

<style scoped lang="scss">
.bars-module {
    @include display-flex();
    @include border-radius();
    flex-direction: column;

    .bars-module-title {
        font-family: $text-light;
        @include font-size(ml);
    }

    .bars-module-content {
        @include font-size(lg);
        color: $color-primary-500;
    }
}
</style>
<style lang="scss">
#report-chart-bar {
    * {
        font-family: $text !important;
    }
    .apexcharts-text tspan {
        @include font-size(xs);
    }
}
</style>
