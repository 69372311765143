<template>
    <div class="filter">
        <label>{{ title }}</label>
        <input
            :class="{
                filtered: currentValue && Object.values(currentValue).length > 0,
                changed: currentValue && currentValue != activeValue
            }"
            :value="currentValue"
            @input="
                (event) => {
                    $emit('inputChange', {
                        inputName: filter,
                        value: event.target.value
                    })
                }
            "
            type="text"
            hide-details
            class="input"
        />
    </div>
</template>

<script>
export default {
    name: 'InputText',
    props: {
        title: { type: String },
        filter: { type: String },
        currentValue: { type: undefined },
        activeValue: { type: undefined }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped></style>
