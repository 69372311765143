var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentValue)?_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.title))]),(Object.values(_vm.optionsSites))?_c('v-select',{staticClass:"select",class:{
            filtered: _vm.currentValue.site && _vm.activeValue.site && _vm.currentValue.site === _vm.activeValue.site,
            changed: _vm.currentValue.site !== _vm.activeValue.site
        },attrs:{"value":_vm.currentValue.site,"hide-details":"","items":Object.values(_vm.optionsSites),"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by')},on:{"input":function (value) {
                _vm.$emit('inputChange', { inputName: 'site', value: value })
            }}}):_vm._e(),(_vm.currentValue.site == 'locations')?_c('div',{staticClass:"sites"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_sites')))]),(Object.values(_vm.optionsLocations))?_c('v-autocomplete',{staticClass:"select",class:{
                filtered: _vm.currentValue.locations && typeof _vm.currentValue.locations !== 'undefined' && Object.values(_vm.currentValue.locations).length > 0,
                changed: JSON.stringify(_vm.currentValue.locations) != JSON.stringify(_vm.activeValue.locations)
            },attrs:{"value":_vm.currentValue.locations,"hide-details":"","items":Object.values(_vm.optionsLocations),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.currentValue.locations ? '' : _vm.$t('assets.filter.filter_by'),"return-object":""},on:{"input":function (locationsInputValue) {
                    _vm.$emit('inputChange', {
                        inputName: 'locations',
                        value: locationsInputValue
                    })
                }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}}],null,false,257687894)}):_vm._e(),(_vm.currentValue.locations)?_c('Tags',{attrs:{"tags":_vm.currentValue.locations,"backImage":'location_inactive_s30.svg',"nameTag":'name',"value":"id","removable":true}}):_vm._e()],1):(_vm.currentValue.site == 'groups')?_c('div',{staticClass:"custom"},[_c('label',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('user.filters.available_territories')))]),(Object.values(_vm.optionsGroups))?_c('v-autocomplete',{staticClass:"select",class:{
                filtered:
                    _vm.currentValue.groups &&
                    typeof _vm.currentValue.groups !== 'undefined' &&
                    _vm.currentValue.groups &&
                    Object.values(_vm.currentValue.groups).length > 0,
                changed: JSON.stringify(_vm.currentValue.groups) != JSON.stringify(_vm.activeValue.groups)
            },attrs:{"value":_vm.currentValue.groups,"hide-details":"","items":Object.values(_vm.optionsGroups),"item-text":"name","item-value":"id","multiple":"","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.currentValue.groups ? '' : _vm.$t('assets.filter.filter_by'),"return-object":""},on:{"input":function (inputValue) {
                    _vm.$emit('inputChange', {
                        inputName: 'groups',
                        value: inputValue
                    })
                }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                "+_vm._s(index == 0 ? '' : '')+"\n            ")]}}],null,false,257687894)}):_vm._e(),(_vm.currentValue.groups)?_c('Tags',{attrs:{"tags":_vm.currentValue.groups,"nameTag":'name',"value":"id","removable":true}}):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }