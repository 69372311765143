var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.title))]),_c('input',{staticClass:"input",class:{
            filtered: _vm.currentValue && Object.values(_vm.currentValue).length > 0,
            changed: _vm.currentValue && _vm.currentValue != _vm.activeValue
        },attrs:{"type":"number","hide-details":""},domProps:{"value":_vm.currentValue},on:{"input":function (event) {
                _vm.$emit('inputChange', {
                    inputName: _vm.filter,
                    value: event.target.value
                })
            }}})])}
var staticRenderFns = []

export { render, staticRenderFns }