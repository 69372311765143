var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items)?_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.title))]),_c('v-autocomplete',{staticClass:"select",class:{
            filtered: _vm.currentValues && Object.values(_vm.currentValues).length > 0,
            changed: JSON.stringify(_vm.currentValues) != JSON.stringify(_vm.activeValues)
        },attrs:{"items":_vm.items,"value":_vm.currentValues,"placeholder":_vm.$t('assets.filter.filter_by'),"no-data-text":_vm.$t('supervise.filter.no_results'),"item-text":"name","item-value":"id","hide-details":"","multiple":"","attach":"","dense":"","return-object":""},on:{"input":function (inputValue) {
                _vm.$emit('inputChange', {
                    inputName: _vm.filter,
                    value: inputValue
                })
            }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_vm._v("\n            "+_vm._s(index == 0 ? '' : '')+"\n        ")]}},{key:"item",fn:function(ref){
            var item = ref.item;
            var attrs = ref.attrs;
            var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{staticClass:"options",attrs:{"no-gutters":""},domProps:{"innerHTML":_vm._s(item.name)}})],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,3218260243)}),_c('Tags',{attrs:{"tags":_vm.currentValues,"nameTag":'name',"value":"id","removable":true}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }