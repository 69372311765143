<template>
    <div class="pie-module">
        <div class="pie-module-content">
            <apexchart
                type="donut"
                :options="pieOption.chartOptions"
                :series="pieOption.series"
                height="400px"
            ></apexchart>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
export default {
    name: 'PieModule',
    components: {
        apexchart: ApexCharts
    },
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            pieOption: {
                series: [],
                height: '150px',
                chartOptions: {
                    chart: {
                        width: '100%',
                        type: 'pie'
                    },
                    colors: chartColors,
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                offset: -5
                            }
                        }
                    },
                    dataLabels: {
                        formatter(val, opts) {
                            return val.toFixed(1) + '%'
                        }
                    },
                    legend: {
                        show: true,
                        position: 'bottom'
                    }
                }
            }
        }
    },

    created() {
        this.pieOption.chartOptions.labels = this.data.map((item) => item.title)
        this.pieOption.series = this.data.map((item) => item.value)
    }
}
</script>

<style scoped lang="scss">
.pie-module {
    @include display-flex();
    @include border-radius();
    flex-direction: column;

    .pie-module-title {
        font-family: $text-light;
        @include font-size(ml);
    }

    .pie-module-content {
        @include font-size(lg);
        color: $color-primary-300;
    }
}
</style>
