<template>
    <div div class="filter">
        <!-- NUMBER -->
        <InputNumber
            v-if="type && type === 'number'"
            :title="title"
            :filter="filter"
            :currentValue="currentValue"
            :activeValue="activeValue"
            @inputChange="(params) => $emit('inputChange', params)"
        />

        <!-- SELECT -->
        <InputSelect
            v-else-if="type && type === 'select'"
            :title="title"
            :filter="filter"
            :currentValues="currentValue"
            :activeValues="activeValue"
            :items="inputSelectItems"
            @inputChange="(params) => $emit('inputChange', params)"
        />

        <!-- DATE -->
        <InputDate
            v-else-if="type && type === 'date'"
            :currentValue="currentValue"
            :activeValue="activeValue"
            @inputChange="(params) => $emit('inputChange', params)"
        />

        <!-- SITE (LOCATIONS and GROUPS) -->
        <InputsSelectLocation
            v-else-if="type && type === 'site'"
            :title="title"
            :currentValue="currentValue"
            :activeValue="activeValue"
            :optionsLocations="optionsLocations"
            :optionsGroups="optionsGroups"
            @inputChange="(params) => $emit('inputChange', params)"
        />

        <!-- TEXT -->
        <InputText
            v-else="type && type === 'text'"
            :title="title"
            :filter="filter"
            :currentValue="currentValue"
            :activeValue="activeValue"
            @inputChange="(params) => $emit('inputChange', params)"
        />
    </div>
</template>

<script>
import InputDate from './filterInputs/InputDate.vue'
import InputText from './filterInputs/InputText.vue'
import InputNumber from './filterInputs/InputNumber.vue'
import InputSelect from './filterInputs/InputSelect.vue'
import InputsSelectLocation from './filterInputs/InputsSelectLocation.vue'

export default {
    name: 'FilterInput',
    components: {
        InputDate,
        InputText,
        InputNumber,
        InputSelect,
        InputsSelectLocation
    },
    props: {
        type: { type: String },
        title: { type: String },
        filter: { type: String },
        currentValue: { type: undefined },
        activeValue: { type: undefined },
        inputSelectItems: { type: Array },
        optionsLocations: { type: Array },
        optionsGroups: { type: Array }
    }
}
</script>

<style lang="scss" scoped></style>
